import _ from 'lodash';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: "ServicesOfClinic",
    data() {
        return {
            defaultProps: {
                children: "has_children",
                label: "name",
            },
            loadData: false,
            clinics: [],
            loading: false
        };
    },
    computed: {
        ...mapGetters({
            rules: "services/rules",
            model: "services/model",
            columns: "services/columns",
            services: "services/services",
        }),
    },
    methods: {
        ...mapActions({
            loadServices: "services/create",
            remoteSearchMethod: 'doctors/remoteMethod'
        }),

        selecetTree(data){
            console.log('data', data);
        },

        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    let services = [];
                    let checkedServices = this.$refs.services.getCheckedNodes();
                    for (let key in checkedServices) {
                        if (checkedServices.hasOwnProperty(key)) {
                        let checkedService = checkedServices[key];
                        services.push(checkedService.id);
                        }
                    }

                    this.form["services"] = services.filter(function (e) {
                        return e;
                    });
                    this.loadingButton = true;
                    this.save(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },

        getServices() {
        if (!this.loadData) {
                this.loadServices()
                .then((res) => {})
                .catch((err) => {
                    this.$alert(err);
                });
            }
        },
        remoteMethod(query){
            if (query) {
                this.loading = true;
                setTimeout(() => {
                    this.remoteSearchMethod({query: query})
                    .then((res) => {
                        if (res.clinics) {
                            this.loading = false;
                            this.clinics = res.clinics;
                        }
                    }).catch((err) => {
                        this.loading = false;
                        console.log(err);
                    }, 2000);
                });
            }else{
                this.clinics = [];
            }
        },
        clearData(clinic_id){
            if (clinic_id) {
                this.clinics = [];
            }
        } 
    }
}