<template>
    <div class="internal-sections add-style-blocks-mi">
        <div class="internal-sections-top">
            <el-row :gutter="20">
                <el-col :span="18">
                    <div class="d-flexk a-i-center">
                        <div class="title-internal-block">Tibbiy Xizmat</div>
                        <div class="filter-internal-block">
                            <el-input
                                prefix-icon="el-icon-search"
                                v-model="filterForm.search"
                                size="small"
                            ></el-input>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <crm-create-and-column-settings
                        @c-create="drawerCreate = true"
                        :columns="columns"
                        @c-change="updateColumn"
                    >
                    </crm-create-and-column-settings>
                </el-col>
            </el-row>
            <el-breadcrumb>
                <el-breadcrumb-item> Xizmatlar </el-breadcrumb-item>
                <el-breadcrumb-item> Tibbiy Xizmat </el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="internal-sections-table">
            <table class="table-my-code table-bordered" v-loading="loadingData">
                <thead>
                    <tr>
                        <th class="w50p" v-if="columns.id.show">
                            {{ columns.id.title }}
                        </th>

                        <th v-if="columns.name.show">
                            {{ columns.name.title }}
                        </th>

                        <th v-if="columns.count_services.show">
                            {{ columns.count_services.title }}
                        </th>

                        <!-- <th v-if="columns.created_at.show">
                            {{ columns.created_at.title }}
                        </th>
                        
                        <th v-if="columns.updated_at.show">
                            {{ columns.updated_at.title }}
                        </th> -->

                        <th v-if="columns.settings.show">
                            {{ columns.settings.title }}
                        </th>
                    </tr>

                    <tr class="filter_sorche">
                        <th v-if="columns.id.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.id"
                                :placeholder="columns.id.title"
                                class="id_input"
                            ></el-input>
                        </th>

                        <th v-if="columns.name.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.nameClick"
                                :placeholder="columns.name.title"
                            ></el-input>
                        </th>

                        <th v-if="columns.count_services.show">
                            <el-input
                                clearable
                                size="mini"
                                v-model="filterForm.seryaNomer"
                                :placeholder="columns.count_services.title"
                            ></el-input>
                        </th>

                        <!-- <th v-if="columns.created_at.show">
                            <el-date-picker
                                :placeholder="columns.created_at.title"
                                v-model="filterForm.dataContract"
                                size="mini"
                            >
                            </el-date-picker>
                        </th>

                        <th v-if="columns.updated_at.show">
                            <el-date-picker
                                :placeholder="columns.updated_at.title"
                                v-model="filterForm.data"
                                size="mini"
                            >
                            </el-date-picker>
                        </th> -->

                        <th class="settinW" v-if="columns.settings.show"></th>
                    </tr>
                </thead>

                <transition-group name="flip-list" tag="tbody">
                    <tr v-for="service in list" :key="service.id" class="cursor-pointer">
                        <td v-if="columns.id.show">{{ service.id }}</td>

                        <td v-if="columns.name.show">{{service.name ? service.name : ''}} </td>

                        <td v-if="columns.count_services.show">{{ service.services_count }}</td>

                        <!-- <td v-if="columns.created_at.show">{{ service.created_at }}</td>

                        <td v-if="columns.updated_at.show">{{ service.updated_at }}</td> -->
   
                        <td v-if="columns.settings.show" class="settings-td">
                            <crm-setting-card
                                :name="$options.name"
                                :model="service"
                                :permissionShow="'nationsController@update'"
                                :permissionDestroy="'nationsController@destroy'"
                                :actions="actions"
                                @edit="edit"
                                @delete="destroy"
                            ></crm-setting-card>
                        </td>
                    </tr>
                </transition-group>
            </table>
        </div>

        <div class="my-modal-big">
            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerCreate"
                size="70%"
                ref="drawerCreate"
                @opened="drawerOpened('drawerCreateChild')"
                @closed="drawerClosed('drawerCreateChild')"
            >
                <crm-create
                    ref="drawerCreateChild"
                    drawer="drawerCreate"
                ></crm-create>
            </el-drawer>

            <el-drawer
                :append-to-body="true"
                :with-header="false"
                :visible.sync="drawerUpdate"
                size="70%"
                ref="drawerUpdate"
                @opened="drawerOpened('drawerUpdateChild')"
                @closed="drawerClosed('drawerUpdateChild')"
            >
                <crm-update
                    :selectedItem="selectedItem"
                    ref="drawerUpdateChild"
                    drawer="drawerUpdate"
                ></crm-update>
            </el-drawer>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';
import list from "@/utils/mixins/list";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
export default {
    name: "services",
    mixins: [list],
    components: {
        CrmCreate,
        CrmUpdate
    },
    computed: {
        ...mapGetters({
            list: "services/list",
            columns: "services/columns",
            pagination: "services/pagination",
            filter: "services/filter",
            sort: "services/sort",
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    data() {
        return {};
    },
    methods: {
        ...mapActions({
            updateList: "services/index",
            setPagination: "services/setPagination",
            updateSort: "services/updateSort",
            updateFilter: "services/updateFilter",
            updateColumn: "services/updateColumn",
            updatePagination: "services/updatePagination",
            show: "services/show",
            empty: "services/empty",
            delete: "services/destroy",
            refreshData: "services/refreshData",
        }),
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('services/EMPTY_LIST');
        next()
    },
};
</script>

