<template >
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                {{
                    $t("message.new_c", {
                        m: $t("message.roles"),
                    })
                }}
            </div>
            <div>
                <crm-store-update-close
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                ></crm-store-update-close>
            </div>
        </div>

        <div class="bodal-body-my">
            <el-form ref="form" status-icon :model="form" :rules="rules">
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item prop="clinic_id" :label="$t('message.name_of_clinic')">
                            <el-select
                                class="w-100"
                                v-model="form.clinic_id"
                                filterable
                                clearable
                                remote
                                :placeholder="$t('message.name_of_clinic')"
                                :remote-method="remoteMethod"
                                @change="clearData"
                                :loading="loading"
                                size="large">
                                <el-option
                                v-for="item in clinics"
                                :key="item.id"
                                :label="item.legal_entity_name"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        
                        <el-form-item>
                            <el-tree
                            :data="[
                            {
                                name: 'Tibbiy Xizmatlar',
                                has_children: services.services,
                            },
                            ]"
                            show-checkbox
                            node-key="id"
                            :props="defaultProps"
                            ref="services"
                            >
                            </el-tree>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <!-- end app-modal__body -->
    </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import service from '@/utils/mixins/models/service';
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
    export default {
        mixins: [service, drawer, form],
        methods: {
            ...mapActions({
                save: 'services/store'
            }),
            afterOpen() {
            this.getServices();
            },
            afterLeave(){
                this.clinics = [];
            }
        }
    }
  </script>